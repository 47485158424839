<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      style="background-color: #00b0ff; border: none"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg svg-icon-white">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text text-white">Riwayat <b>Stok Bahan</b></div>
      <b-button
        squared
        style="background-color: #3506a7; border: none; color: white"
        @click="$router.push('/stock-history/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-between mt-n3">
                <div class="col-md-6 col-12">
                  <span class="d-none d-sm-block">
                    <b-input-group>
                      <template #prepend>
                        <b-button
                          squared
                          @click="resetFilterPeriode"
                          variant="danger"
                          >Reset</b-button
                        >
                        <b-button
                          squared
                          @click="filterByPeriode"
                          variant="success"
                          >Cari</b-button
                        >
                      </template>
                      <b-form-input
                        v-model="filter.start_date"
                        type="text"
                        placeholder="Tanggal Awal"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.start_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                        ></b-form-datepicker>
                      </b-input-group-append>
                      <b-button squared variant="light">s/d</b-button>
                      <b-form-input
                        v-model="filter.end_date"
                        type="text"
                        placeholder="Tanggal Akhir"
                        autocomplete="off"
                        readonly
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="filter.end_date"
                          button-only
                          right
                          reset-button
                          locale="id"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </span>
                  <span class="d-block d-sm-none">
                    <div class="row">
                      <div class="col-12 pb-1">
                        <b-input-group>
                          <template #prepend>
                            <b-button
                              squared
                              @click="resetFilterPeriode"
                              variant="danger"
                              >Reset</b-button
                            >
                            <b-button
                              squared
                              @click="filterByPeriode"
                              variant="success"
                              >Cari</b-button
                            >
                          </template>
                          <b-form-input
                            v-model="filter.start_date"
                            type="text"
                            placeholder="Tanggal Awal"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.start_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <div class="col-12 pt-0">
                        <b-input-group>
                          <b-button squared variant="light">s/d</b-button>
                          <b-form-input
                            v-model="filter.end_date"
                            type="text"
                            placeholder="Tanggal Akhir"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="filter.end_date"
                              button-only
                              right
                              reset-button
                              locale="id"
                              aria-controls="example-input"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </div>
                  </span>
                </div>
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.material_stock_id"
                    placeholder="Saring Berdasar Bahan"
                    @select="filterByMaterialStock"
                    :options="materialStocks"
                  />
                </div>
                <div
                  class="col-md-3"
                  v-if="
                    currentUser.role_key_name == 'super_admin' ||
                    currentUser.user_type_id == 3
                  "
                >
                  <treeselect
                    v-model="filter.posyandu_id"
                    :multiple="false"
                    placeholder="Saring Posyandu"
                    :options="posyandu_list"
                    @input="filterByPosyandu"
                  />
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="
                  currentUser.role_key_name == 'super_admin' ||
                  currentUser.user_type_id == 3
                    ? fieldsSpecial
                    : fields
                "
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '25%' : '' }"
                  />
                </template>

                <template #cell(date)="data">
                  {{
                    new Date(data.item.date).toLocaleString("id-ID", {
                      weekday: "long",
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    })
                  }}
                </template>

                <template #cell(display_flow)="data">
                  <i
                    v-if="data.item.flow == 0"
                    class="fas fa-arrow-down"
                    style="color: green"
                    v-b-tooltip.hover
                    title="Stok Masuk"
                  ></i>
                  <i
                    v-if="data.item.flow == 1"
                    class="fas fa-arrow-up"
                    style="color: red"
                    v-b-tooltip.hover
                    title="Stok Keluar"
                  ></i>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/stock-history/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/stock-history/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <!-- <b-button
                    size="sm"
                    class="btn-danger"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btnAccess"
                  ><i class="fas fa-trash px-0"></i></b-button> -->
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "stock-histories",

  data() {
    return {
      currentUser: getUser(),
      // filter
      filter: {
        material_stock_id: "",
        start_date: "",
        end_date: "",
        posyandu_id:
          getUser().role_key_name == "super_admin" ||
          getUser().user_type_id == 3
            ? ""
            : getUser().posyandu_id,
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "material_stock_name",
          label: "Nama Bahan",
          sortable: true,
        },
        {
          key: "display_flow",
          label: "Keluar/masuk",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Jumlah",
          sortable: true,
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
        },
        {
          key: "description",
          label: "Keterangan",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      fieldsSpecial: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "posyandu_name",
          label: "Posyandu",
          sortable: true,
        },
        {
          key: "date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "material_stock_name",
          label: "Nama Bahan",
          sortable: true,
        },
        {
          key: "display_flow",
          label: "Keluar/masuk",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Jumlah",
          sortable: true,
        },
        {
          key: "remaining_stock",
          label: "Sisa Stok",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      posyandu_list: [],
      // options
      materialStocks: [],
      // access
      btnAccess: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async getMaterialStockOptions() {
      let response = await module.setTreeSelect(`api/material-stocks?posyandu_id=${this.filter.posyandu_id}`);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.materialStocks = response.data;
        this.materialStocks.unshift({
          label: "Saring Berdasar Bahan",
          id: "",
          isDisabled: true,
        });
      }
    },

    async filterByMaterialStock(evt) {
      await this.filter.material_stock_id;
      this.pagination();
    },

    filterByPeriode() {
      this.pagination();
    },

    resetFilterPeriode() {
      console.log("filter reset", this.filter);
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.filter.material_stock_id = "";
      this.filter.posyandu_id =
        getUser().role_key_name == "super_admin" || getUser().user_type_id == 3
          ? ""
          : getUser().posyandu_id;

      this.pagination();
    },

    async pagination() {
      let filterParams = `&posyandu_id=${this.filter.posyandu_id}&name=${this.filter.name}&material_stock_id=${this.filter.material_stock_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        "api/stock-histories",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getPosyanduOption() {
      let response = await module.setTreeSelect("api/posyandu");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.posyandu_list = response.data;
        this.posyandu_list.unshift({
          label: "Semua Posyandu",
          id: "",
        });
        this.posyandu_list.unshift({
          label: "Saring Posyandu",
          id: 0,
          isDisabled: true,
        });
      }
    },

    filterByPosyandu(evt) {
      if (typeof evt != "undefined") {
        this.filter.posyandu_id = evt;
        this.pagination();
        this.getMaterialStockOptions()
      } else {
        this.filter.posyandu_id = "";
        this.pagination();
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete(`api/stock-histories/${id}`);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3004") {
          this.btnAccess = true;
        }
      }
    },
  },

  watch: {
    "filter.material_stock_id": function (newVal, oldVal) {
      if (typeof newVal === "undefined") {
        this.filter.material_stock_id = null;
        this.filterByMaterialStock();
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Riwayat Stok Bahan", route: "/stock-history" },
    ]);
    this.getMaterialStockOptions();
    this.pagination();
    this.checkAccess();
    this.getPosyanduOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
